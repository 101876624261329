/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media
only screen and (max-width: 760px)  {

	.p-xs-0 {
			padding: 0px !important;
	}

	.tabs-container {
		display: flex;
  	flex-wrap: wrap;
	}

	.tabs-container ul li {
		flex: 1 0 50%;
	  white-space: nowrap;
	  text-align: center;
	  box-sizing: border-box;
		border: 1px solid #eee !important;
	}

	.tabs-container ul li a {
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
	}

	.table-responsive {}
	.table-responsive .html5buttons {display:inline-block;width:45%;overflow:hidden;}
	.table-responsive #boatsTable_filter{display:inline-block;width:55%;overflow:hidden;}

	.dataTables_length {
		display: none;
	}

	/* iBox Content */
	.ibox-responsive .ibox-title {display: block; min-height: unset; height: 77px; padding: 10px;}
	.ibox-responsive .ibox-title h5 {width: 100%; display: block;}
	.ibox-responsive .ibox-title .ibox-tools-btn {display: inline-block; top: auto;}

	/* Force table to not be like tables anymore */
	table.responsive,
  table.responsive thead,
  table.responsive tbody,
  table.responsive th,
  table.responsive td,
  table.responsive tr {
		display: block;
	}

	table.responsive thead tr {
		display: none;
	}

	table.responsive tbody td {
		border: none !important;
		position: relative;
    text-align: left!important;
	}

	.mobile-preview {
		display: flex;
		border: 1px solid var(--main-color);
	}

	.mobile-preview .img-wrapper {
		width: 20%;
		overflow: hidden;
	}

	.mobile-preview.mbl-prv-bo .mobile-text {
		width: 70%;
	}

	.mobile-preview.mbl-prv-co .mobile-text {
		width: 90%;
	}

	.mobile-preview.mbl-prv-co .mobile-text.two-columns .single-col {
		width: 50%;
		display: inline-table;
		line-break: anywhere;
	}

	.mobile-preview .mobile-btn-wrapper {
		width: 10%;
		overflow: hidden;
	}

	.mobile-preview .btn-view {
		width: 100%;
		height: 100%;
		background-color: var(--main-color);
		display: inline-block;
		color: #fff;
		line-height: 60px;
		text-align: center;
	}

}
