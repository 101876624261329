@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700&display=swap');

:root {
  --main-color: #272842;
  --secondary-color: #555572;
  --highlight-color: #f2f2f2;
}

body {
  background-color: var(--main-color);
  border-top: 10px solid var(--main-color);
  font-size: 0.85rem;
  font-family: 'Assistant', sans-serif;
}

.navbar,
#page-wrapper {
    background-color: var(--highlight-color);
}

body.mini-navbar .nav-header {
  background-color: var(--secondary-color);
}

.navbar .text-muted,
.nav.navbar-right > li > a {
  color: var(--main-color) !important;
}

.nav-header {
  background-color: var(--main-color);
  background-image: none;
  padding: 15px;
}

.nav .nav-link {
  background-color: #e0dfdf;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  margin-left: 1px;
  padding: 7px 15px;
  font-weight: normal;
  color: #495057;
}

.nav li.active a {
  color: var(--main-color);
}

.nav > li.active {
    border-color: var(--secondary-color);
    background-color: var(--highlight-color);
}

.nav > li .nav-second-level li.active {
    background-color: var(--secondary-color);
}

.nav .count {
  font-style: normal;
  font-weight: lighter;
  font-size: 10px;
}

.nav-second-level li a {
    padding-left: 15px !important;
    color: #fff !important;
}

.page-item.active .page-link {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.navbar-form-custom {
  float: left;
  height: 30px;
  padding: 0;
  width:290px;
  display: block;
  margin: 14px 5px 5px 10px;
}

.navbar-form-custom .form-control {
  border: 1px solid #e5e6e7;
  background-color: #eeeeee;
  font-size: 14px;
  height: 30px;
  margin: 0;
  width: 240px;
  z-index: 2000;
}

.navbar-form-custom .form-group {
  margin-bottom: 0;
}

.btn-primary {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.select2-dropdown{
  z-index:9999;
}

.form-control,
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  background-color: #eeeeee;
  font-size: 0.9rem;
  border-radius: 0;
}

.middle-box h1 {
  font-size: 120px;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  line-height: 30px;
  height: 30px;
}

.wizard > .content {
  min-height: 350px !important;
}

/*appstore & playstore buttons*/
.big-btn {
  padding: 10px 0px 11px 0px;
  width: 215px;
  margin-top: 5px;
}

.big-btn i {
  font-size: 65px;
  margin-right: 0px !important;
}

.big-btn strong {
  font-size: 25px;
  text-shadow: 1px 2px 0px #34659B;
  color: #fff;
  line-height: 24px;
}

.big-btn .btn-text {
  margin-top: 6px;
}

.big-btn small {
  font-size: 75%;
}

.android-btn {

  width: 240px;
  padding: 13px 17px 13px 0px;
  background-color: #000;
  border-color: #000;

}

.android-btn:hover {
  background-color: #272727;
}

.android-btn i {
  font-size: 55px;
}

.android-btn .btn-text {
  margin-top: 0px;
}

.android-btn strong {
  font-size: 25px;
  text-shadow: none;
  color: #fff;
  line-height: 24px;
}

.windows-btn {
  background-color: #008A00;
  width: 235px;
  padding: 13px 5px 13px 10px;
  border-color: #055805;
}

.windows-btn:hover {
  background-color: #008A00;
}

.windows-btn .btn-text {
  text-shadow: none;
  font-size: 26px;
  line-height: 25px;
}

/* jquery-steps colors */
.wizard,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden;
}

.wizard a,
.tabcontrol a {
  outline: 0;
}

.wizard ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

.wizard ul > li,
.tabcontrol ul > li {
  display: block;
  padding: 0;
}

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em;
}

.wizard > .content > .title,
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em;
}

/*
    Wizard
*/

.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%;
}

.wizard > .steps > ul > li {
  width: 25%;
}

.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: left;
}

.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 8px;
  text-decoration: none;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  background: #eee;
  color: #aaa;
  cursor: default;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: #1AB394;
  color: #fff;
  cursor: default;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
  background: #6fd1bd;
  color: #fff;
}

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active {
  background: #ED5565;
  color: #fff;
}

.wizard > .content {
  background: #eee;
  display: block;
  margin: 5px 5px 10px 5px;
  min-height: 120px;
  overflow: hidden;
  position: relative;
  width: auto;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.wizard-big.wizard > .content {
  min-height: 320px;
}

.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em 2.5%;
  width: 65%;
}

.wizard > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}

.wizard > .content > .body ul {
  list-style: disc !important;
}

.wizard > .content > .body ul > li {
  display: list-item;
}

.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}

.wizard > .content > .body input {
  display: block;
  border: 1px solid #ccc;
}

.wizard > .content > .body input[type="checkbox"] {
  display: inline-block;
}

.wizard > .content > .body input.error {
  background: rgb(251, 227, 228);
  border: 1px solid #fbc2c4;
  color: #8a1f11;
}

.wizard > .content > .body label {
  display: inline-block;
  margin-bottom: 0.5em;
}

.wizard > .content > .body label.error {
  color: #8a1f11;
  display: inline-block;
  margin-left: 1.5em;
}

.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}

.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}

.wizard > .actions > ul {
  display: inline-block;
  text-align: right;
}

.wizard > .actions > ul > li {
  margin: 0 0.5em;
}

.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  background: #1AB394;
  color: #fff;
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
  background: #eee;
  color: #aaa;
}

/*
    Tabcontrol
*/

.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}

.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;

  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0;
}

.tabcontrol > .steps > ul > li.current {
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}

.tabcontrol > .steps > ul > li > a {
  color: #5f5f5f;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover {
  text-decoration: none;
}

.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px 30px;
}

.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px;
}

.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}

.tabcontrol > .content > .body ul {
  list-style: disc !important;
}

.tabcontrol > .content > .body ul > li {
  display: list-item;
}

tr.row-clickable {
  cursor: pointer;
}

.label-li {
  min-width: 120px;
  font-weight: bold;
}

.input-ssm {
  height: 28px;
  border-radius: 0.2rem;
  color: #444;
  font-size: 13px;
}

.input-ssm::placeholder {
  color: #999;
  font-size: 13px;
}

.select2 {
  width: 100% !important;
}

.wizard > .content {
  min-height: 400px !important;
}

.file .icon,
.file .image {
  height: 150px !important;
  overflow: hidden;
}

.file .icon {
  padding: 55px 0px;
  text-align: center;
}

.file .icon i {
  font-size: 40px;
  color: #dadada;
}

label.required:after {
  color: #e32;
  content: ' *';
  display: inline;
}

/* media gallery hover effects */
.mediaGallery .hover, .mediaGallery .hover .overlay {
  width: 100%;
  height: 100%;
  overflow: hidden
}

.mediaGallery .hover, .mediaGallery .hover h2 {
  text-align: center
}

.mediaGallery .hover {
  position: relative;
}

.mediaGallery .hover .overlay {
  position: absolute;
  top: 30px;
  left: 0;
}

.mediaGallery .hover img {
  display: block;
  position: relative
}

.mediaGallery .hover h2 {
  position: relative;
  font-size: 17px;
  padding: 10px;
  background: rgba(0, 0, 0, .6)
}

.mediaGallery .hover button.info {
  text-decoration: none;
  padding: 2px 4px;
  border: 1px solid #fff;
  margin: 20px 0 0;
  border-radius: 0;
  background-color: #5f5f5f;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
}

.mediaGallery .hover button.info:hover {
  box-shadow: 0 0 5px #fff
}

.mediaGallery .ehover3 img {
  -webkit-transition: all .4s ease-in;
  transition: all .4s ease-in
}

.mediaGallery .ehover3 button.info, .ehover3 h2 {
  -webkit-transform: scale(.7);
  transform: scale(.7);
  -webkit-transition: all .4s ease-in;
  transition: all .4s ease-in;
  opacity: 0
}

.mediaGallery .ehover3:hover img {
  filter: grayscale(1) blur(3px);
  -webkit-filter: grayscale(1) blur(3px);
  -webkit-transform: scale(1.2);
  transform: scale(1.2)
}

.mediaGallery .ehover3:hover button.info, .ehover3:hover h2 {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.profile-picture-spinner-container {
  padding-top: 60px;
  height: 150px;
  width: 150px;
}

.form-error-icon {
  display: none;
}

.ibox-tools-btn {
  display: block;
  float: none;
  margin-top: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  text-align: right;
}

.pricing-plan {
  margin: 20px 30px 0 30px;
  border-radius: 4px;
}

.pricing-plan.selected {
  /*transform: scale(1.1);*/
  background: #f4f4f4;
}

.pricing-plan li {
  padding: 10px 16px;
  border-top: 1px solid #e7eaec;
  text-align: center;
  color: #aeaeae;
}

.pricing-plan .pricing-price span {
  font-weight: 700;
  color: #1ab394;
}

li.pricing-desc {
  font-size: 13px;
  border-top: none;
  padding: 20px 16px;
}

li.pricing-title {
  background: #1ab394;
  color: #fff;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  font-size: 22px;
  font-weight: 600;
}

.table-wrapper-scroll-y {
  position: relative;
  height: 365px;
  overflow: auto;
}

.spinner-text {
  float: left;
  margin: 45px 0;
  font-weight: bolder;
}

.custom-error {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #cc0000 !important;
}

.modalSelect2 {
  z-index: 9999;
}


/* iCheck plugin Square skin, green
----------------------------------- */
.icheckbox_square-green,
.iradio_square-green {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url('~iCheck/skins/square/blue.png') no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-green {
  background-position: 0 0;
}

.icheckbox_square-green.hover {
  background-position: -24px 0;
}

.icheckbox_square-green.checked {
  background-position: -48px 0;
}

.icheckbox_square-green.disabled {
  background-position: -72px 0;
  cursor: default;
}

.icheckbox_square-green.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-green {
  background-position: -120px 0;
}

.iradio_square-green.hover {
  background-position: -144px 0;
}

.iradio_square-green.checked {
  background-position: -168px 0;
}

.iradio_square-green.disabled {
  background-position: -192px 0;
  cursor: default;
}

.iradio_square-green.checked.disabled {
  background-position: -216px 0;
}

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_square-green,
  .iradio_square-green {
    background-image: url('~iCheck/skins/square/blue@2x.png');
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}

#sortableArea {
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-height: 300px;
}

.mediaGallery {
  min-height: 300px;
}

#sortableArea li {
  float: left;
  text-align: center;
}

.file-on-folder {
  width: 80px!important;
  top:200px;
}

.file-on-folder a {
  display: none;
}

.sunseeker-bg {
  background-color: #272842;
}

.icheck-col .form-check {
  padding-left: unset;
  width:30px;
  float: left;
}

table.table-bordered {
  border: 1px solid var(--secondary-color) !important;
}

table tbody tr td.clickable {
  cursor: pointer;
}

table tbody tr td .well {
  padding: 5px;
  background-color: #eee;
  border-radius: 5px;
  display: block;
}

.contact-title-bar {
  background-color: #fff;
  border-bottom: 2px solid var(--secondary-color);
}

.contact-title-bar h2 {
  color: var(--main-color);
  font-weight: 600;
}

.contact-title-bar h2 span {
  font-weight: normal;
  font-size: 1rem;
}

.contact-title-bar p {
  margin: 0;
  font-size: 0.8rem;
}

.contact-title-bar label {
  color: var(--main-color);
  font-weight: 600;
  margin: 0;
  font-size: 0.7rem;
}

textarea.h150 {
  height: 150px;
}

.footer {
  font-size: 0.7rem;
}

/* Tables */
#notesTable tr td {
  word-break: break-all;
}

.table-striped {
  border: 1px solid var(--secondary-color);
  border-collapse: collapse;
  border-spacing: 0;
}

.table-striped thead tr th {
  border: 1px solid var(--secondary-color);
  padding: 5px;
}

.table-striped tbody tr td {
  border: 1px solid var(--secondary-color);
  padding: 5px;
}

.table-striped tbody tr:hover td {
  background-color: #555572;
  color: #fff;
}

.table-striped tbody tr:hover td a {
  color: #fff;
}

.table-striped tbody tr:hover td .search-string, 
.table-striped tbody tr:hover td .card-body, 
.table-striped tbody tr:hover td .well {
  color: var(--secondary-color);
}

.table tr.header,
.table tr.header th {
  border: 1px solid var(--secondary-color);
  background-color: #c7c7c7;
}

/* Custom layout for the select2 drop down */
.select2-results ul li { padding-left: 30px; }

.select2-results .select2-results__option[aria-selected=true],
.select2-results .select2-results__option[aria-selected=false] {
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 5px center;
}

.select2-results .select2-results__option[aria-selected=false] {
  background-image: url('/theme/img/icons/square-regular.svg');
}

.select2-results .select2-results__option[aria-selected=true] {
  background-image: url('/theme/img/icons/check-square-solid.svg');
}

/* Searching multiple makes */
ul.makes {
  list-style: none;
  padding: 0;
}

/* Transations */
.english-text {
  height: 200px; overflow-y: scroll; width: 100%; border: 1px solid #eee; padding: 10px; display: inline-block;
}

.datepicker {
  z-index: 3000 !important;
}

@media (max-width: 768px) {
  .navbar-default {
    width: 100% !important;
    background-color: #272842;
    min-height: 100vh;
    top: 0px;
  }

  .navbar-default .nav-header {
    background-color: #555572;
  }
}

/* UL Tree View */
.tree, .tree ul {
  margin:0;
  padding:0;
  list-style:none
}
.tree ul {
  margin-left:1em;
  position:relative
}
.tree ul ul {
  margin-left:.5em
}
.tree ul:before {
  content:"";
  display:block;
  width:0;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  border-left:1px solid
}
.tree li {
  margin:0;
  padding:0 1em;
  line-height:2em;
  position:relative;
  font-size: 14px;
}
.tree ul li:before {
  content:"";
  display:block;
  width:10px;
  height:0;
  border-top:1px solid;
  margin-top:-1px;
  position:absolute;
  top:1em;
  left:0
}
.tree ul li:last-child:before {
  background:#fff;
  height:auto;
  top:1em;
  bottom:0
}
.indicator {
  margin-right:5px;
}

.tree li button, .tree li button:active, .tree li button:focus {
  text-decoration: none;
  color:#369;
  border:none;
  background:transparent;
  margin:0px 0px 0px 0px;
  padding:0px 0px 0px 0px;
  outline: 0;
}