.tickercontainer {
  background-color: var(--secondary-color);
}

.tickercontainer ul {
  font-weight: 300 !important;
  font-size: 12px !important;
}

.tickercontainer ul li strong {
  font-weight: bold;
}
