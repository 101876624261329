.login-wrapper {
  position: relative;
  height: 80vh;
}

.login-form {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.btn-login {
  background-color: var(--secondary-color)!important;
  color: #272842!important;
}
